<template>
  <div class="dashboard_wrap">
    <!-- <div class="first_row">
      <div class="title_blue"><span></span>听证数量统计</div>
      <el-row :gutter="10">
        <el-col v-for="item in statisticsList" :key="item.name"
          ><div class="grid-content">
            <span class="statistics_img"></span>
            <div>
              <div class="statistics_count">
                {{ item.value }}
                <p>件</p>
              </div>
              <p class="statistics_name">{{ item.name }}</p>
            </div>
          </div></el-col
        >
      </el-row>
    </div> -->
    <div class="first_row">
      <div class="title_blue"><span></span>用户统计</div>
      <el-row :gutter="10">
        <el-col v-for="item in statisticsList1" :key="item.name"
          ><div class="grid-content">
            <span class="statistics_img"></span>
            <div>
              <div class="statistics_count">
                {{ item.value }}
                <p>位</p>
              </div>
              <p class="statistics_name">{{ item.name }}</p>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="third_row">
      <div class="title_blue"><span></span>听证数量统计</div>
      <div id="bar_dv" ref="chart"></div>
    </div>
    <div class="second_row">
      <div class="title_blue"><span></span>数据统计名称</div>
      <div class="pie_wrap">
        <div id="pie_dv" ref="chart_pieSexy"></div>
        <div id="pie_dv2" ref="chart_pieMajor"></div>
      </div>
    </div>
    <!-- 开关 -->
    <div class="first_row">
      <div class="title_blue"><span></span>微信短信信息开关</div>
      <div class="btnstatus">
        <div class="btnstatusleft">
          <el-switch @change="_updateSendStatus"
            v-model="dstate"
            active-text="启用短信发送"
            inactive-text="取消短信发送"
          >
          </el-switch>
        </div>
        <div class="btnstatusright">
          <el-switch @change="_updateSendStatus1"
            v-model="vstate" 
            active-text="启用微信发送"
            inactive-text="取消微信发送"
          >
          </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getSendStatus,
  updateSendStatus,
  getbackhringinfo,
  getuserPerCount,
  getSexCount,
  getuserstatistical
} from "@/api/dashboardApi";
import { Message } from "element-ui";

  // ,
  // 
  // 
  // getlineChart,
// import { isTok } from "@/api/api";
// import router from "@/router";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      statisticsList: [],
      statisticsList1: [],
      getGenderData: [],
      getSpecialtyData: [],
      getlineChartData: [],
      dstate : '',//短信
      vstate : '',//微信
    };
  },
  mounted() {
    this.userInfo = JSON.parse(window.localStorage.getItem("tltz_userinfo"));
    this._isTok();
  },
  methods: {
    //判断token是否过期
    async _isTok() {  
      // await this._getbackhringinfo();
      if(JSON.parse( window.localStorage.getItem("tltz_userinfo") ).account == 'admin'){
        await this._getuserstatistical();
      }
      await this._getSendStatus();
      await getSexCount(this.userInfo.id).then((data) => {
        this.getGenderData = data.data;
        this._pieSexy();
      });
      await getuserPerCount(this.userInfo.id).then((data) => {
        this.getSpecialtyData = data.data;
        this._pieMajor();
      });
      await getbackhringinfo(this.userInfo.id).then((data) => {
        // this.getlineChartData = data.data;
        const key = [];
        const value = [];
        data.data.forEach(item =>{
          key.push(item.name)
        })
        data.data.forEach(item =>{
          value.push(item.value)
        })
        this.getlineChartData = { key,value};
        this._echart();
        console.log(this.getlineChartData[0]);
      });
      // isTok().then((data) => {
      //   console.log(data.msg);
      //   if (data.msg == "true") {
      //     console.log(1);
      //     this._getJobQuantity();
      //     this._pieSexy();
      //     this._pieMajor();
      //     return;
      //   }
      //   this.$alert("当前登录状态失效，请重新登录！", "提示", {
      //     confirmButtonText: "确定",
      //     callback: () => {
      //       router.replace("/login");
      //     },
      //   });
      // });
    },
    //列表
    _getbackhringinfo() {
      getbackhringinfo(this.userInfo.id).then((data) => {
        if (!data) {
          return;
        }
        this.statisticsList = data.data;
      });
    },
    _getuserstatistical() {
      getuserstatistical(this.userInfo.id).then((data) => {
        if (!data) {
          return;
        }
        this.statisticsList1 = data.data;
      });
    },
    //短信发送状态
    _getSendStatus(){
      getSendStatus().then((data) =>{
        if(data.data[0].isswitch == 1){
          this.dstate = true;
        }else{
          this.dstate = false;
        }
        if(data.data[1].isswitch == 1){
          this.vstate = true;
        }else{
          this.vstate = false;
        } 
        console.log(data.data)
      })
    },
    //改变短信发送状态
    _updateSendStatus(e){
      console.log(e)
      const sms = {
        id: 1,
        isswitch:''
      }
      if( e == false){
        sms.isswitch = 0
      }else{
        sms.isswitch = 1
      }      
      updateSendStatus(sms).then((data) =>{
        if(data.code == 200){
          Message.success("更改成功！");
        }else{
          this.dstate = !this.vstate;
        }
      })
    },
    _updateSendStatus1(e){
      console.log(e)
      console.log(e)
      const sms = {
        id: 2,
        isswitch:''
      }
      if( e == false){
        sms.isswitch = 0
      }else{
        sms.isswitch = 1
      }      
      updateSendStatus(sms).then((data) =>{
        if(data.code == 200){
          Message.success("更改成功！");
        }else{
          this.vstate = !this.vstate;
        }
      })
    },
    //折线图
    _echart() {
      var chartDom = this.$refs.chart;
      var myChart = echarts.init(chartDom);
      var option;
      option = {
           grid: {
        left: '5%',
        right: '5%',
        bottom: '8%',
        top: '5%',
        containLabel: true
    },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.getlineChartData.key,
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
        },

        yAxis: {
          type: "value",
          minInterval: 1,
        },
        series: [
          {
            name: "数量",
            data: this.getlineChartData.value,
            type: "line",
            showSymbol: false,
            smooth: true,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    //性别 饼
    _pieSexy() {
      console.log(this.getGenderData, 1);
      var chartDom_pieSexy = this.$refs.chart_pieSexy;
      var myChart_pieSexy = echarts.init(chartDom_pieSexy);
      var option_pieSexy;
      option_pieSexy = {
        title: {
          text: "专家男女占比图",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          // type: "scroll",
          orient: "vertical",
          left: "left",
          top: 20,
        },
        series: [
          {
            name: "性别",
            type: "pie",
            radius: "50%",
            data: this.getGenderData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      console.log(this.getGenderData, 2);
      option_pieSexy && myChart_pieSexy.setOption(option_pieSexy);
    },
    //专业 饼
    _pieMajor() {
      var chartDom_pieMajor = this.$refs.chart_pieMajor;
      var myChart_pieMajor = echarts.init(chartDom_pieMajor);
      var option_pieMajor;
      option_pieMajor = {
        title: {
          text: "各专业方向占比图",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 20,
        },
        series: [
          {
            name: "专业",
            type: "pie",
            radius: "50%",
            data: this.getSpecialtyData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option_pieMajor && myChart_pieMajor.setOption(option_pieMajor);
    },
  },
};
</script>
<style lang='less' >
.dashboard_wrap {
  box-sizing: border-box;
  width: auto;
  height: 88%;
  background: #e9eef3;
  margin-top: 16px;
  padding: 0px 13px 0 13px;
  .first_row {
    background: #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .btnstatus {
      display: flex;
      height: 100px;
      align-items: centent !important;
      justify-content: center;
      .btnstatusleft {
        display: flex;
        align-items: center;
        margin-right: 100px;
      }
      .btnstatusright {
        display: flex;
        align-items: center;
        margin-left: 100px;
      }
    }
    .grid-content {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e9ecf7;
      height: 130px;
      > span {
        width: 68px;
        height: 68px;
        margin-right: 12px;
      }
      .statistics_count {
        font-size: 30px;
        > p {
          display: inline-block;
          font-size: 14px;
        }
      }
      .statistics_name {
        font-size: 12px;
        margin-top: 10px;
      }
    }
    .el-col:nth-child(1n) {
      .statistics_count {
        color: #3a8fea;
      }
      span {
        background: url("../assets/zhcpIcon/police_stat.png");
        background-size: 100% 100%;
      }
    }
    .el-col:nth-child(2n) {
      .statistics_count {
        color: #eb623b;
      }
      span {
        background: url("../assets/zhcpIcon/loader_stat.png");
        background-size: 100% 100%;
      }
    }

    .el-col:nth-child(3n) {
      .statistics_count {
        color: #0facd5;
      }
      span {
        background: url("../assets/zhcpIcon/principal_stat.png");
        background-size: 100% 100%;
      }
    }
    .el-col:nth-child(4n) {
      .statistics_count {
        color: #f09a33;
      }
      span {
        background: url("../assets/zhcpIcon/secondary_stat.png");
        background-size: 100% 100%;
      }
    }
    .el-col:nth-child(5n) {
      .statistics_count {
        color: #36cfc9;
      }
      span {
        background: url("../assets/zhcpIcon/clark_stat.png");
        background-size: 100% 100%;
      }
    }
    .el-col:nth-child(6n) {
      .statistics_count {
        color: #1f77fe;
      }
      span {
        background: url("../assets/zhcpIcon/loan_stat.png");
        background-size: 100% 100%;
      }
    }
  }

  .third_row {
    background: #fff;
    height: 270px;
    margin-bottom: 10px;
    #bar_dv {
      height: 250px;
    }
  }
  .second_row {
    background: #ffffff;
    height: 270px;
    margin-bottom: 10px;
    .pie_wrap {
      display: flex;
      justify-content: center;
    }
    #pie_dv {
      height: 250px;
      width: 30%;
    }
    #pie_dv2 {
      height: 250px;
      width: 50%;
    }
  }
}
.title_blue {
  display: flex;
  padding: 10px 0 10px 0;
  > span {
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #4966d2;
    margin-right: 11px;
  }
  
}
.el-row {
  display: flex;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.grid-content {
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
}
</style>